import React, {useEffect, useState} from 'react';
import styles from './TherapyArticle.module.css';
import {ReactComponent as TrashIcon} from "../../../../../assets/icons/trashIcon.svg";
import {ReactComponent as EditIcon} from "../../../../../assets/icons/edit_icon.svg";
import ConfirmationModal from "../../../../UI/Modals/ConfirmationModal/ConfirmationModal";
import ConfigData from "../../../../../config.json";
import DefaultImage from "../../../../../assets/PictureHolder.png";

const TherapyArticle = (props) => {

    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const [image, imageSetter] = useState();

    useEffect(() => {
        if (props.article.itemResponse) {
            imageSetter(props.article.itemResponse && props.article.itemResponse.pictures ? ConfigData.ArticleImageLink + props.article.itemResponse.pictures : DefaultImage);
        }
        if (props.article.item) {
            imageSetter(props.article.item && props.article.item.picture ? ConfigData.ArticleImageLink + props.article.item.picture : DefaultImage);
        }
    }, [props.article]);

    const closeConfirmModal = () => {
        setConfirmModalVisible(false)
    }

    const openConfirmModal = (e) => {
        e.stopPropagation();
        setConfirmModalVisible(true)
    }

    return (
        <div className={styles.TherapyArticle}>
            <div className={styles.WrapperForTherapyImage}>
                <img className={styles.ImageForTherapy}
                     src={image}
                     alt="article image"
                />
            </div>
            <div style={{width:"100%"}}>
                <div className={styles.ArticleNameAndQtyWrapper}>
                    <div>
                        {props.article.itemResponse && props.article.itemResponse.name} {/*pregled artikala u postojecoj terapiji*/}
                        {props.article.item && props.article.item.name} {/*pregled artikala u postojecoj terapiji tek po kreiranju terapije / pregled artikla u toku kreiranju terapije*/}
                    </div>
                    <div style={{paddingRight: "1rem", display: "flex", alignItems: "center"}}>
                        {props.editItem && <EditIcon className={styles.IconStyle} style={{paddingRight: "1rem"}} onClick={props.editItem}/>}
                        {props.removeItem && <TrashIcon className={styles.IconStyle} onClick={openConfirmModal}/>}
                    </div>
                </div>
                <div className={styles.Information}>
                    <div className={styles.ArticleLabels}>
                        <span className={styles.ArticleLabel}>Količina</span>
                        <span className={styles.ArticleLabel}>Doza</span>
                        <span className={styles.ArticleLabel}>Frekvencija</span>
                        <span className={styles.ArticleLabel}>Trajanje</span>
                    </div>
                    <div className={styles.ArticleDetails}>
                        <div className={styles.ArticleDetails}>
                            {props.article.quantity}
                        </div>
                        <span className={styles.ArticleDetails}>{props.article.dosing}</span>
                        <span className={styles.ArticleDetails}>{props.article.frequency}</span>
                        <span className={styles.ArticleDetails}>{props.article.duration}</span>
                    </div>
                </div>
            </div>
            {confirmModalVisible &&
                <ConfirmationModal
                    show={confirmModalVisible}
                    text={
                        <div>
                            <span
                                style={{fontWeight: "normal"}}>Da li ste sigurni da želite da izbrišete artikal:</span>
                            {props.article.item.name}
                        </div>}
                    denyButton={closeConfirmModal}
                    confirmButton={()=>{props.removeItem(); closeConfirmModal()}}
                />
            }
        </div>
    );
};

export default TherapyArticle;