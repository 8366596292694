import React from 'react';
import {ReactComponent as RFZOImage} from "../../../../assets/icons/rfzo_logo.svg";
import styles from './RFZOButton.module.css';

const RFZOButton = (props) => {
    return (
        <div className={styles.RFZOButtonWrapper} onClick={props.onClick}>
            <RFZOImage />&nbsp;
            {/*<img src={RFZOSign} alt="/"/>&nbsp;*/}
            <span>RFZO Terapija</span>
        </div>
    );
};

export default RFZOButton;