import React, {Fragment, useEffect, useState} from "react";
import {withRouter} from 'react-router-dom'
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import styles from "./Article.module.css";
import DefaultImage from "../../../../../assets/PictureHolder.png";
import {redirectToError} from "../../../../../containers/App";
import ConfigData from "../../../../../config.json";
import Media from "react-media";
import { ChevronRightIcon } from "@heroicons/react/outline";
import RegulativaNotice from "../../../../UI/RegulativaNotice/RegulativaNotice";
import Button from "./../../../../UI/Buttons/Button/Button";
import IsLoginModal from "../../../../ShopPage/ShopPageModals/IsLoginModal";
import NoAddressModal from "../../../NoAddressModal/NoAddressModal";
import LoyaltyBadge from "../../../../UI/LoyaltyBadge/LoyaltyBadge";
import {textToUrl} from "../../../../GobalFunctions/GlobalFunctions";
import ItemAvailabilityBadge from "../../../../UI/ItemAvailabilityBadge/ItemAvailabilityBadge";

const Article = (props) => {

    useEffect(() => {

        //provera zbog mape, da kada vraca informaije o artiklu ne poziva server ako se artikl nije promeni,
        //vec da ucita podatke o artiklu iz postojeceg redux store-a
        if (!window.location.pathname.includes('searched-stores')) {
            if (props.articleDetails === null || (props.articleDetails.id !== +props.match.params.id)) {
                props.onFetchDetailsForArticle(props.match.params.id);
            }
        }
    }, [props.match.params.id])


    useEffect(() => {
        if (!props.loadingArticleDetails) {
            if (props.articleDetailsError) {
                redirectToError(props);
            }
            if (props.articleDetails !== null)
                props.onSearchBarPlaceholderChanged(props.articleDetails.name);
        }
    }, [props.articleDetails])


    const [isPhotoButtonPressed, isPhotoButtonPressedHandler] = useState(false)

    const [isLoginModalVisible, isLoginModalVisibleHandler] = useState(false);

    const [isNoAddressModalVisible, isNoAddressModalVisibleHandler] = useState(false)

    const articleInfoHandler = () => {
        localStorage.setItem('beforeEnteringInfoLek', window.location.pathname);
        props.onArticleDetailsForInfoPage(props.articleDetails);
        if (props.articleDetails.jkl === '') props.articleDetails.jkl = null;

        const url = '/articles-info/' + props.articleDetails.jkl + "/" + props.articleDetails.id + "/" + textToUrl(props.articleDetails.name);
        props.history.push(url);
    }

    const showItemPhoto = () => {
        isPhotoButtonPressedHandler(true);
        setTimeout(() => {
            isPhotoButtonPressedHandler(false);
        }, 3000)
    }

    const openCNCModal = () => {
        props.onCNCModalToggle(true);
    }

    const openLoginModal = () => {
        isLoginModalVisibleHandler(true);
    }

    const closeLoginModal = () => {
        isLoginModalVisibleHandler(false);
    }

    const redirectToLogin = () => {
        localStorage.setItem('beforeLogin', window.location.pathname);
        props.history.push("/auth/login");
    }

    const noAddressModal = () => {
        isNoAddressModalVisibleHandler(true);
    }

    const closeNoAddressModal = () => {
        isNoAddressModalVisibleHandler(false);
    }

    const openEnterAddressModal =() => {
        isNoAddressModalVisibleHandler(false);
        props.onChooseLocationModalToggle(true);
    }

    const loyaltyPointsIcon =
        <div className={styles.LoyaltyPointsIcon}>
            <LoyaltyBadge
                number={props.articleDetails && props.articleDetails.itemLoyalityPoints/10}
            />
        </div>

    let article

    if (props.articleDetails !== null) {

        article =
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}
            >
                {matches => (
                    <Fragment>
                        {matches.small &&
                        <div className={styles.ArticleInfo} id="header">
                            <div className={styles.ArticleImageHolder}>
                                {props.articleDetails.itemAvailabilityCode &&
                                    <ItemAvailabilityBadge itemResponse={props.articleDetails} />
                                }
                                <div style={{position: "absolute", right: "70px", top: "14px"}}>
                                    {props.articleDetails.itemLoyalityPoints > 0 && loyaltyPointsIcon}
                                </div>
                                {(props.articleDetails.itemAvailabilityCode !== 'BR' && props.articleDetails.jkl !== null && props.articleDetails.jkl.trim() !== "") ?

                                    <RegulativaNotice imgStyle={{width: "140px"}}
                                                      style={{position: "relative", display: "flex", alignItems: "center", justifyContent:"center", margin:"1rem 0"}}
                                                      iconStyle={{width: "32px", stroke: "#F58300"}}
                                                      wrappingStyle={{display:"flex", flexDirection:"column", alignItems:"center"}}

                                    />
                                    :
                                    <img className={styles.ArticleImage} id="slika"
                                         src={props.articleDetails.pictures !== null ? ConfigData.ArticleImageLink + props.articleDetails.pictures : DefaultImage}
                                         alt=""/>
                                }
                            </div>
                            <p className={styles.ArticleTitle} id="header"
                               onClick={articleInfoHandler}>{props.articleDetails.name}</p>
                            <div className={styles.ArticleInfoActions}>
                                <Button buttontype="blue"
                                        disabled={props.storesError}
                                        style={!props.storesError ? {width: "140px", height: "44px", fontSize: "14px"} : {backgroundColor: "lightgray", color: "white", width: "140px", height: "44px", fontSize: "14px", cursor: "auto"}}
                                        onClick={!localStorage.getItem('enteredAddress') ? noAddressModal : (props.isLoggedIn ? openCNCModal : openLoginModal)}
                                >
                                    Poruči i preuzmi
                                </Button>

                                <div onClick={articleInfoHandler} className={styles.ArticleInfoHandler}>
                                    Saznaj više &nbsp;
                                    <ChevronRightIcon style={{stroke: "#00B2B1", width: "14px"}}/>
                                </div>
                            </div>
                        </div>

                        // <div className={styles.ArticleInfo} id="header">
                        //     <div className={styles.ArticleInfoActions} style={{display: "flex", alignItems: "center", paddingLeft: "0.5rem"}}
                        //          onClick={articleInfoHandler}>
                        //         <p style={{cursor: "pointer"}}>
                        //             Detaljnije o ovom artiklu &nbsp;
                        //         </p>
                        //         <InformationCircleIcon style={{width: "22px", height: "22px"}}/>
                        //     </div>
                        // </div>
                        }
                        {matches.large &&
                        <div className={styles.Article}>
                            <div className={styles.MiniBox}>
                                <div className={styles.ArticleImageHolder}>
                                    {props.articleDetails.itemAvailabilityCode &&
                                        <ItemAvailabilityBadge itemResponse={props.articleDetails}/>
                                    }
                                    {(props.articleDetails.itemAvailabilityCode !== 'BR' && props.articleDetails.jkl !== null && props.articleDetails.jkl.trim() !== "") ?

                                        // !isPhotoButtonPressed ? <h3 onClick={showItemPhoto} style={{
                                        //         textDecoration: "underline",
                                        //         cursor: "pointer",
                                        //         color: "#848688",
                                        //         marginTop: "50px"
                                        //     }}>Prikaži fotografiju</h3>
                                        //     :
                                        //     <img className={styles.ArticleImage} id="slika"
                                        //          src={props.articleDetails.pictures !== null ? ConfigData.ArticleImageLink + props.articleDetails.pictures[0] : DefaultImage}
                                        //          alt=""/>
                                        <RegulativaNotice imgStyle={{width: "140px"}}
                                                          style={{position: "absolute", bottom: "0", left: "0", display: "flex", alignItems: "center"}}
                                                          iconStyle={{width: "32px", stroke: "#F58300"}}
                                        />
                                        :
                                        <img className={styles.ArticleImage} id="slika"
                                             src={props.articleDetails.pictures !== null ? ConfigData.ArticleImageLink + props.articleDetails.pictures : DefaultImage}
                                             alt=""/>
                                    }
                                    <div style={{position:"absolute", top:"14px", right:"60px"}}>
                                        {props.articleDetails.itemLoyalityPoints > 0 && loyaltyPointsIcon}
                                    </div>
                                </div>
                                <div className={styles.ArticleInfo}>
                                    <p className={styles.ArticleTitle} id="header"
                                       onClick={articleInfoHandler}>{props.articleDetails.name}</p>
                                    <div className={styles.ArticleInfoActions}>
                                        <Button buttontype="blue"
                                                disabled={props.storesError}
                                                style={!props.storesError ? {width: "140px", height: "44px", fontSize: "14px"} : {backgroundColor: "lightgray", color: "white", width: "140px", height: "44px", fontSize: "14px", cursor: "auto"}}
                                                onClick={!localStorage.getItem('enteredAddress') ? noAddressModal : (props.isLoggedIn ? openCNCModal : openLoginModal)}
                                        >
                                            Poruči i preuzmi
                                        </Button>


                                        <div onClick={articleInfoHandler} className={styles.ArticleInfoHandler}>
                                            Saznaj više &nbsp;
                                            <ChevronRightIcon style={{stroke: "#00B2B1", width: "14px"}}/>
                                        </div>


                                    </div>
                                    {/*<div className={styles.PriceAndDetailsWrapper}*/}
                                    {/*     style={props.minPrice !== -1 ? null : {justifyContent: "flex-end"}}>*/}
                                    {/*    {props.minPrice !== -1 ? <p className={styles.PriceRange}>*/}
                                    {/*        Opseg cena:*/}
                                    {/*        {props.minPrice} - {props.maxPrice} RSD</p> : null}*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        }
                    </Fragment>
                )}
            </Media>
    }

    return (
        <>
            {article}
            {!props.isLogedin && isLoginModalVisible &&
                <IsLoginModal onCloseClick={closeLoginModal}
                              show={isLoginModalVisible}
                              backdropClickHandler={closeLoginModal}
                              notLoggedIn={redirectToLogin}
                              cancel={closeLoginModal}
                />
            }

            {isNoAddressModalVisible &&
                <NoAddressModal onCloseClick={closeNoAddressModal}
                                show={isNoAddressModalVisible}
                                backdropClickHandler={closeNoAddressModal}
                                cancel={closeNoAddressModal}
                                openEnterAddressModal={openEnterAddressModal}
                />
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        articleDetails: state.appPage.articleDetails,
        loadingArticleDetails: state.appPage.loadingArticleDetails,
        articleDetailsError: state.appPage.articleDetailsError,

        minPrice: state.appPage.minPrice,
        maxPrice: state.appPage.maxPrice,
        modalResData: state.modal.modalResData,

        storesError: state.appPage.storesError,

        isLoggedIn: state.authPage.token !== null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onModalResHandler: (modalResData) => dispatch(actions.modalResHandler(modalResData)),
        onArticleDetailsForInfoPage: (articleDetails) => dispatch(actions.articleDetailsForInfoPage(articleDetails)),
        onFetchDetailsForArticle: (articleId) => dispatch(actions.fetchDetailsForArticle(articleId)),
        onSearchBarPlaceholderChanged: (string) => dispatch(actions.searchBarPlaceholderChanged(string)),
        onCNCModalToggle: (cncModalToggle) => dispatch(actions.CNCModalToggle(cncModalToggle)),
        onChooseLocationModalToggle: (chooseLocationModalToggle) => dispatch(actions.chooseLocationModalToggle(chooseLocationModalToggle)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Article));