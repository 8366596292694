import * as actionsTypes from './actionsTypes'
import axios from '../../axios/axios-apoteka'


export const allArticlesForSingleShop = (singleShopArticles) => {
    return {
        type: actionsTypes.ALL_SINGLE_STORE_ARTICLES,
        singleShopArticles: singleShopArticles

    };
}

export const sliceOfArticlesForSingleShop = (singleShopArticles) => {
    return {
        type: actionsTypes.SLICE_OF_SINGLE_STORE_ARTICLES,
        singleShopArticles: singleShopArticles

    };
}

export const fetchArticlesForSingleShopFailed = () => {
    return {
        type: actionsTypes.FETCH_SINGLE_STORE_ARTICLES_FAILED
    };
}

export const fetchArticlesForSingleShopStart = () => {
    return {
        type: actionsTypes.FETCH_SINGLE_STORE_ARTICLES_START
    };
}

//STARI FILTERI
// // za itemFilter prima jedan od parametara OTHER_ITEM, PRESCRIPTION_DRUG_ITEM, SUPPLEMENT_ITEM, DRUG_ITEM
// export const fetchAllArticlesForSingleShop = (branchMarketplaceId, marketplaceId, page, itemFilter='OTHER_ITEM', numberOfReturns = 32) => {
//     return dispatch => {
//         dispatch(fetchArticlesForSingleShopStart());
//         const URL = '/item_in_marketplace/items/'+ branchMarketplaceId  + '/' + marketplaceId + '/' + page
//             + '?numOfReturns=' + numberOfReturns + '&itemFilter=' + itemFilter;
//         axios.get(URL)
//             .then(response => {
//                 dispatch(allArticlesForSingleShop(response.data));
//                 console.log(response.data);
//             })
//             .catch(error => {
//                 dispatch(fetchArticlesForSingleShopFailed());
//                 console.log(error);
//             })
//     };
// }

// STARI FILTERI
// // za itemFilter prima jedan od parametara OTHER_ITEM, PRESCRIPTION_DRUG_ITEM, SUPPLEMENT_ITEM, DRUG_ITEM
// export const fetchSliceOfArticlesForSingleShop = (branchMarketplaceId, marketplaceId, page, itemFilter='OTHER_ITEM', numberOfReturns = 32) => {
//     return dispatch => {
//         dispatch(fetchArticlesForSingleShopStart());
//         const URL = '/item_in_marketplace/items/slice/'+ branchMarketplaceId  + '/' + marketplaceId + '/' + page
//             + '?numOfReturns=' + numberOfReturns + '&itemFilter=' + itemFilter;
//         axios.get(URL)
//             .then(response => {
//                 dispatch(sliceOfArticlesForSingleShop(response.data));
//                 console.log(response.data);
//             })
//             .catch(error => {
//                 dispatch(fetchArticlesForSingleShopFailed());
//                 console.log(error);
//             })
//     };
// }

// NOVE KATEGORIJE
///item_in_marketplace/items/0/223/1?numOfReturns=32&groupId=1&filter=item.id in (3368,5993,2666,9228,3606)
export const fetchAllArticlesForSingleShop = (branchMarketplaceId, marketplaceId, page, categoryId=null, targetArray= null, loyaltyFilter = false, discountFilter = false, sortState = '-1', numberOfReturns = 32) => {
    return dispatch => {
        let URL
        let targetArrayString = "";
        let filter;
        let sort;
        if (targetArray !== null){
            dispatch(fetchArticlesForArticlesIdsStart());
            targetArrayString = "(";
            targetArray.forEach(target => {
                targetArrayString = targetArrayString.concat(target.id);
                targetArrayString = targetArrayString.concat(",");
            })
            targetArrayString = targetArrayString.slice(0, -1);
            targetArrayString = targetArrayString.concat(")");

        }
        else {
            dispatch(fetchArticlesForSingleShopStart());
        }

        if (targetArrayString !== "" || loyaltyFilter || discountFilter) {
            filter = "&filter=";
            if (targetArrayString !== "") {
                filter += "item.id in " + targetArrayString;
                if(loyaltyFilter && discountFilter) {
                    filter += " and item.itemLoyalityPoints > 0 and itemPriceWithDiscount is not null and itemPriceWithDiscount > 0";
                } else if(loyaltyFilter && !discountFilter) {
                    filter += " and item.itemLoyalityPoints > 0";
                } else if(!loyaltyFilter && discountFilter) {
                    filter += " and itemPriceWithDiscount is not null and itemPriceWithDiscount > 0";
                }
            } else {
                if(loyaltyFilter && discountFilter) {
                    filter += "item.itemLoyalityPoints > 0 and itemPriceWithDiscount is not null and itemPriceWithDiscount > 0"
                } else if (loyaltyFilter && !discountFilter) {
                    filter += "item.itemLoyalityPoints > 0"
                } else if (!loyaltyFilter && discountFilter) {
                    filter += "itemPriceWithDiscount is not null and itemPriceWithDiscount > 0"
                }
            }
        } else {
            filter = ""
        }

        if (sortState === 'asc') {
            sort = "?sort=itemLowestPrice:asc&"
        } else if (sortState === 'desc') {
            sort = "?sort=itemLowestPrice:desc&"
        } else {
            sort = "?"
        }

        URL = '/item_in_marketplace/items/'+ branchMarketplaceId  + '/' + marketplaceId + '/' + page
            + sort + 'numOfReturns=' + numberOfReturns + (categoryId !== null ? '&groupId=' + categoryId : "") + filter;

        // const URL = 'https://apoteka.rs:8443/apoteka_rs_server/item_in_marketplace/items/223/1/0?numOfReturns=32'

        axios.get(URL)
            .then(response => {
                targetArray !== null ? dispatch(allArticlesForArticlesIds(response.data)) : dispatch(allArticlesForSingleShop(response.data));
                console.log(response.data);
            })
            .catch(error => {
                targetArray !== null ? dispatch(fetchArticlesForArticlesIdsFailed()) : dispatch(fetchArticlesForSingleShopFailed());
                console.log(error);
            })
    };
}

// NOVE KATEGORIJE
///item_in_marketplace/items/0/223/1?numOfReturns=32&groupId=1&filter=item.id in (3368,5993,2666,9228,3606)
export const fetchSliceOfArticlesForSingleShop = (branchMarketplaceId, marketplaceId, page, categoryId=null, targetArray= null, sortState = '-1', numberOfReturns = 32) => {
    return dispatch => {

        let URL;
        let sort;
        console.log(targetArray);
        let targetArrayString = "";
        if (targetArray !== null){
            dispatch(fetchArticlesForArticlesIdsStart());
            targetArrayString = "(";
            targetArray.forEach(target => {
                targetArrayString+=target.id;
                targetArrayString+=",";
            })
            targetArrayString = targetArrayString.slice(0, targetArrayString-1);
            targetArrayString += ")";

        }
        else {
            dispatch(fetchArticlesForSingleShopStart());
        }

        if (sortState === 'asc') {
            sort = "?sort=itemLowestPrice:asc&"
        } else if (sortState === 'desc') {
            sort = "?sort=itemLowestPrice:desc&"
        } else {
            sort = "?"
        }

        URL = '/item_in_marketplace/items/slice/' + branchMarketplaceId  + '/' + marketplaceId + '/' + page
            + sort + 'numOfReturns=' + numberOfReturns + (categoryId !== null ? '&groupId=' + categoryId : "") + (targetArrayString !== "" ? "&filter=item.id in " + targetArrayString : "");

        axios.get(URL)
            .then(response => {
                targetArray !== null ? dispatch(sliceOfArticlesForArticlesIds(response.data)) : dispatch(sliceOfArticlesForSingleShop(response.data));
                console.log(response.data);
            })
            .catch(error => {
                targetArray !== null ? dispatch(fetchArticlesForArticlesIdsFailed()) : dispatch(fetchArticlesForSingleShopFailed());
                console.log(error);
            })
    };
}



//actions for fetching articles for articlesIds for shop
export const allArticlesForArticlesIds = (articlesForArticlesIds) => {
    return {
        type: actionsTypes.ALL_STORE_ARTICLES_FOR_ARTICLES_IDS,
        articlesForArticlesIds: articlesForArticlesIds

    };
}

export const sliceOfArticlesForArticlesIds = (articlesForArticlesIds) => {
    return {
        type: actionsTypes.SLICE_OF_STORE_ARTICLES_FOR_ARTICLES_IDS,
        articlesForArticlesIds: articlesForArticlesIds

    };
}

export const fetchArticlesForArticlesIdsFailed = () => {
    return {
        type: actionsTypes.FETCH_STORE_ARTICLES_FOR_ARTICLES_IDS_FAILED
    };
}

export const fetchArticlesForArticlesIdsStart = () => {
    return {
        type: actionsTypes.FETCH_STORE_ARTICLES_FOR_ARTICLES_IDS_START
    };
}

export const fetchAllArticlesForArticlesIds = (itemsIds, branchMarketplaceId, marketplaceId, page, itemFilter='OTHER_ITEM') => {
    return dispatch => {
        dispatch(fetchArticlesForArticlesIdsStart());
        const URL = '/item_in_marketplace/items/'+ page + '/' + branchMarketplaceId  + '/' + marketplaceId +
            '?numOfReturns=32' + '&itemFilter=' + itemFilter;
        axios.post(URL, itemsIds)
            .then(response => {
                dispatch(allArticlesForArticlesIds(response.data));
                console.log(response.data);
            })
            .catch(error => {
                dispatch(fetchArticlesForArticlesIdsFailed());
                console.log(error);
            })
    };
}

export const fetchSliceOfArticlesForArticlesIds = (itemsIds, branchMarketplaceId, marketplaceId, page, itemFilter='OTHER_ITEM') => {
    return dispatch => {
        dispatch(fetchArticlesForArticlesIdsStart());
        const URL = '/item_in_marketplace/items/slice/'+ page + '/' + branchMarketplaceId  + '/' + marketplaceId +
            '?numOfReturns=32' + '&itemFilter=' + itemFilter;
        axios.post(URL, itemsIds)
            .then(response => {
                dispatch(sliceOfArticlesForArticlesIds(response.data));
                console.log(response.data);
            })
            .catch(error => {
                dispatch(fetchArticlesForArticlesIdsFailed());
                console.log(error);
            })
    };
}

//actions for article names for search bar
export const namesOfAllArticlesForSingleShop = (namesOfSingleShopArticles) => {
    return {
        type: actionsTypes.NAME_OF_ALL_SINGLE_STORE_ARTICLES,
        namesOfSingleShopArticles: namesOfSingleShopArticles

    };
}

export const fetchNamesOfAllArticlesForSingleShopFailed = () => {
    return {
        type: actionsTypes.FETCH_NAME_OF_ALL_SINGLE_STORE_ARTICLES_FAILED
    };
}

export const fetchNamesOfAllArticlesForSingleShopStart = () => {
    return {
        type: actionsTypes.FETCH_NAME_OF_ALL_SINGLE_STORE_ARTICLES_START
    };
}

export const fetchNamesOfAllArticlesForSingleShop = (branchMarketplaceId, marketplaceId) => {
    return dispatch => {
        dispatch(fetchNamesOfAllArticlesForSingleShopStart());
        const URL = '/item_in_marketplace/names/'+ branchMarketplaceId  + '/' + marketplaceId;
        axios.get(URL)
            .then(response => {
                dispatch(namesOfAllArticlesForSingleShop(response.data));
                console.log(response.data);
            })
            .catch(error => {
                dispatch(fetchNamesOfAllArticlesForSingleShopFailed());
                console.log(error);
            })
    };
}

//actions for fetching selected item in store
export const selectArticle = (selectedArticle) => {
    return {
        type: actionsTypes.FETCH_SELECTED_ARTICLE,
        selectedArticle: selectedArticle

    };
}

export const deselectArticle = () => {
    return {
        type: actionsTypes.DESELECT_SELECTED_ARTICLE

    };
}

export const fetchSelectedArticleFailed = () => {
    return {
        type: actionsTypes.FETCH_SELECTED_ARTICLE_FAILED
    };
}

export const fetchSelectedArticleStart = () => {
    return {
        type: actionsTypes.FETCH_SELECTED_ARTICLE_START
    };
}

export const fetchSelectedArticle = (itemId, branchMarketplaceId, marketplaceId) => {
    return dispatch => {
        dispatch(fetchSelectedArticleStart());
        const URL = '/item_in_marketplace/' + itemId + '/' + branchMarketplaceId  + '/' + marketplaceId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(selectArticle(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchSelectedArticleFailed())
            })
    };
}

//actions for fetching shopping cart
export const shoppingCart = (shoppingCart) => {
    return {
        type: actionsTypes.FETCH_SHOPPING_CART,
        shoppingCart: shoppingCart

    };
}

export const fetchShoppingCartFailed = () => {
    return {
        type: actionsTypes.FETCH_SHOPPING_CART_FAILED
    };
}

export const fetchShoppingCartStart = () => {
    return {
        type: actionsTypes.FETCH_SHOPPING_CART_START
    };
}

export const fetchShoppingCart = (userId, branchMarketplaceId, marketplaceId) => {
    return dispatch => {
        dispatch(fetchShoppingCartStart());
        const URL = '/shopping_cart/' + userId + '/' + branchMarketplaceId  + '/' + marketplaceId;
        axios.get(URL)
            .then(response => {
                console.log(response.data)
                dispatch(shoppingCart(response.data));
                dispatch(initiateAddToShoppingCartAnimation(true));
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchShoppingCartFailed())
            })
    };
}

export const itemToShoppingCart = (item) => {
    return {
        type: actionsTypes.ADD_ITEM_TO_SHOPPING_CART,
        item: item
    };
}

export const addItemToShoppingCartFailed = () => {
    return {
        type: actionsTypes.ADD_ITEM_TO_SHOPPING_CART_FAILED
    };
}

export const addItemToShoppingCartStart = () => {
    return {
        type: actionsTypes.ADD_ITEM_TO_SHOPPING_CART_START
    };
}

export const addItemToShoppingCart = (userId, branchMarketplaceId, marketplaceId, item) => {
    return dispatch => {
        dispatch(addItemToShoppingCartStart());
        const URL = '/shopping_cart/' + userId + '/' + marketplaceId + '/' + branchMarketplaceId;
        axios.post(URL, item)
            .then(response => {
                console.log(response.data)
                dispatch(itemToShoppingCart(response.data))
            })
            .catch(error => {
                console.log(error);
                dispatch(addItemToShoppingCartFailed())
            })
    };
}

export const addItemToShoppingCartUsingItemId = (userId, branchMarketplaceId, marketplaceId, itemId) => {
    return dispatch => {
        dispatch(addItemToShoppingCartStart());
        const URL = '/shopping_cart/' + userId + '/' + marketplaceId + '/' + branchMarketplaceId + '/' + itemId;
        axios.post(URL)
            .then(response => {
                console.log(response.data)
                dispatch(itemToShoppingCart(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(addItemToShoppingCartFailed())
            })
    };
}


export const removeItemFromShoppingCart = (item, total) => {
    return {
        type: actionsTypes.REMOVE_ITEM_FROM_SHOPPING_CART,
        item: item,
        total: total
    };
}

export const removeItemFromShoppingCartFailed = () => {
    return {
        type: actionsTypes.REMOVE_ITEM_FROM_SHOPPING_CART_FAILED
    };
}

export const removeItemFromShoppingCartStart = () => {
    return {
        type: actionsTypes.REMOVE_ITEM_FROM_SHOPPING_CART_START
    };
}

export const removeItemFromShoppingCartAction = (userId, branchMarketplaceId, marketplaceId, item) => {
    return dispatch => {
        dispatch(removeItemFromShoppingCartStart());
        const URL = '/shopping_cart/' + item.itemResponse.id + '/' + userId + '/' + branchMarketplaceId + '/' + marketplaceId;
        axios.delete(URL)
            .then(response => {
                console.log(response.data)
                dispatch(removeItemFromShoppingCart(item, response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(removeItemFromShoppingCartFailed())
            })
    };
}

export const clearShoppingCart = () => {
    return {
        type: actionsTypes.CLEAR_SHOPPING_CART,
    };
}

export const clearShoppingCartFailed = () => {
    return {
        type: actionsTypes.CLEAR_SHOPPING_CART_FAILED
    };
}

export const clearShoppingCartStart = () => {
    return {
        type: actionsTypes.CLEAR_SHOPPING_CART_START
    };
}

export const clearShoppingCartAction = (userId, branchMarketplaceId, marketplaceId) => {
    return dispatch => {
        dispatch(clearShoppingCartStart());
        const URL = '/shopping_cart/' + userId + '/' + branchMarketplaceId + '/' + marketplaceId;
        axios.delete(URL)
            .then(response => {
                console.log(response.data)
                dispatch(clearShoppingCart())
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(clearShoppingCartFailed())
            })
    };
}

export const changeQuantityOfShoppingCartItem = (item) => {
    return {
        type: actionsTypes.CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM,
        item: item
    };
}

export const changeQuantityOfShoppingCartItemFailed = () => {
    return {
        type: actionsTypes.CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM_FAILED
    };
}

export const changeQuantityOfShoppingCartItemStart = () => {
    return {
        type: actionsTypes.CHANGE_QUANTITY_OF_SHOPPING_CART_ITEM_START
    };
}

export const changeQuantityOfShoppingCartAction = (newQuantity, itemId, userId, branchMarketplaceId, marketplaceId) => {
    return dispatch => {
        dispatch(changeQuantityOfShoppingCartItemStart());
        const URL = '/shopping_cart/' + newQuantity + '/' + itemId + '/' + userId + '/' + branchMarketplaceId + '/' + marketplaceId;
        axios.put(URL)
            .then(response => {
                console.log(response.data)
                dispatch(changeQuantityOfShoppingCartItem(response.data))
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(changeQuantityOfShoppingCartItemFailed())
            })
    };
}

//actions for fetching all shopping cart for a user
export const allShoppingCarts = (allShoppingCarts) => {
    return {
        type: actionsTypes.FETCH_ALL_SHOPPING_CARTS,
        allShoppingCarts: allShoppingCarts,
    };
}

export const fetchAllShoppingCartsFailed = () => {
    return {
        type: actionsTypes.FETCH_ALL_SHOPPING_CARTS_FAILED
    };
}

export const fetchAllShoppingCartsStart = () => {
    return {
        type: actionsTypes.FETCH_ALL_SHOPPING_CARTS_START
    };
}

export const fetchAllShoppingCarts = (userId) => {
    return dispatch => {
        dispatch(fetchAllShoppingCartsStart());
        const URL = '/shopping_cart/' + userId;
        axios.get(URL)
            .then(response => {
                if (response.data === "") {
                    dispatch(initiateAddToShoppingCartAnimation(true));
                }
                else {
                    dispatch(allShoppingCarts(response.data));
                }

            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(fetchAllShoppingCartsFailed())
            })
    };
}

export const initiateAddToShoppingCartAnimation = (initAnimationValue) => {
    return {
        type: actionsTypes.INITIATE_ADD_TO_SHOPPING_CART_ANIMATION,
        initAnimationValue: initAnimationValue
    };
}

//actions for prescription images
export const uploadPrescriptionsFinished = (prescriptionsLinks) => {
    return {
        type: actionsTypes.UPLOAD_PRESCRIPTIONS_FINISHED,
        prescriptionsLinks: prescriptionsLinks
    };
}

export const uploadPrescriptionsFailed = () => {
    return {
        type: actionsTypes.UPLOAD_PRESCRIPTIONS_FAILED
    };
}

export const uploadPrescriptionsStart = () => {
    return {
        type: actionsTypes.UPLOAD_PRESCRIPTIONS_START
    };
}

export const uploadPrescriptions = (userId, branchMarketplaceId, marketplaceId, data) => {
    return dispatch => {
        dispatch(uploadPrescriptionsStart());
        const URL = '/prescription/' + userId + '/' + marketplaceId + '/' + branchMarketplaceId;
        axios.post(URL, data)
            .then(response => {
                console.log(response.data)
                dispatch(uploadPrescriptionsFinished(response.data))
            })
            .catch(error => {
                dispatch(uploadPrescriptionsFailed())
            })
    };
}

//delete single prescription image
export const deletePrescriptionFinished = (prescriptionId) => {
    return {
        type: actionsTypes.DELETE_PRESCRIPTION,
        prescriptionId: prescriptionId
    };
}

export const deletePrescriptionFailed = () => {
    return {
        type: actionsTypes.DELETE_PRESCRIPTION_FAILED
    };
}

export const deletePrescriptionStart = () => {
    return {
        type: actionsTypes.DELETE_PRESCRIPTION_START
    };
}

export const deletePrescription = (userId, branchMarketplaceId, marketplaceId, prescriptionId) => {
    return dispatch => {
        dispatch(deletePrescriptionStart());
        const URL = '/prescription/' + userId + '/' + marketplaceId + '/' + branchMarketplaceId + '/' + prescriptionId;
        axios.delete(URL)
            .then(response => {
                console.log(response.data)
                dispatch(deletePrescriptionFinished(prescriptionId))
            })
            .catch(error => {
                dispatch(deletePrescriptionFailed())
            })
    };
}

//delete all prescriptions
export const deleteAllPrescriptionsFinished = () => {
    return {
        type: actionsTypes.DELETE_ALL_PRESCRIPTIONS
    };
}

export const deleteAllPrescriptionsFailed = () => {
    return {
        type: actionsTypes.DELETE_ALL_PRESCRIPTIONS_FAILED
    };
}

export const deleteAllPrescriptionsStart = () => {
    return {
        type: actionsTypes.DELETE_ALL_PRESCRIPTIONS_START
    };
}

export const deleteAllPrescriptions = (userId, branchMarketplaceId, marketplaceId) => {
    return dispatch => {
        dispatch(deleteAllPrescriptionsStart());
        const URL = '/prescription/all/' + userId + '/' + marketplaceId + '/' + branchMarketplaceId;
        axios.delete(URL)
            .then(response => {
                console.log(response.data)
                dispatch(deleteAllPrescriptionsFinished())
            })
            .catch(error => {
                dispatch(deleteAllPrescriptionsFailed())
            })
    };
}

export const returnNumberOfItemsInShoppingCart = (numberOfItemsInShoppingCart) => {
    return {
        type: actionsTypes.NUMBER_OF_ITEMS_IN_SHOPPING_CART,
        numberOfItemsInShoppingCart: numberOfItemsInShoppingCart
    };
}

export const sortShopArticles = (value) => {
    return {
        type: actionsTypes.SORT_SHOP_ARTICLES,
        sortValue: value
    }
}