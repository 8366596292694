import React from "react";
import styles from './CMSPage.module.css'
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import Button from "../UI/Buttons/Button/Button";
import Wrapper from "../UI/Wrapper/Wrapper";

const CMSPage = (props) => {

    const logoutHandler = () => {
        props.onLogout();
        props.onResetUserInfo(null);
        props.onResetUserProfile(null);
    }

    const redirectToArticleCMSHandler = () => {
        let url = 'cms/master-artikal-cms/6'
        props.history.push(url);
    }

    const redirectToStoresCMSHandler = () => {
        let url = 'cms/prodajno-mesto-cms'
        props.history.push(url);
    }

    return (
        <Wrapper>
            <div className={styles.CMSPage}>
                <div className={styles.ActionButtonsHolder}>
                    <Button onClick={redirectToArticleCMSHandler}
                            style={{backgroundColor:"grey", cursor:"default"}}
                            buttontype="blue"
                    >CMS za proizvode</Button>
                    <Button onClick={redirectToStoresCMSHandler}
                            style={{marginLeft: "1rem"}}
                            buttontype="blue"
                    >CMS za prodajna
                        mesta</Button>
                </div>
                <br/>
                <Button onClick={logoutHandler}
                        buttontype="blue"
                >logout</Button>
            </div>
        </Wrapper>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout()),
        onResetUserInfo: (userInfo) => dispatch(actions.userInfo(userInfo)),
        onResetUserProfile: (userProfile) => dispatch(actions.userProfile(userProfile))

    }
}

export default connect(null, mapDispatchToProps)(CMSPage);
