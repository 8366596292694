import React, {Fragment, useEffect, useState} from 'react';
import styles from './ArticleInfoPage.module.css';
import Toolbar from '../Navigation/Toolbar/Toolbar'
import 'react-tabs/style/react-tabs.css';
import ArticleInfoLek from "./ArticleInfoLek/ArticleInfoLek";
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import DefaultImage from "../../assets/PictureHolder.png";
import {withRouter} from 'react-router-dom'
import ArticleImageHolder from "./ArticleImageHolder/ArticleImageHolder";
import ArticleDetailsHolder from "./ArticleDetailsHolder/ArticleDetailsHolder";
import ArticleActionsHolder from "./ArticleActionsHolder/ArticleActionsHolder"
import {ExclamationCircleIcon, LocationMarkerIcon} from "@heroicons/react/outline";
import ConfigData from "../../config.json";
import LiveChatButton from "../UI/LiveChatButton/LiveChatButton";
import Button from './../UI/Buttons/Button/Button'
import Media from "react-media";
import HeaderWithTitleAndBackChevron from "../../componentsRes/HeaderWithTitleAndBackChevron/HeaderWithTitleAndBackChevron";
import RegulativaIcon from "../../assets/icons/regulativa.svg";
import {ReactComponent as RegulativaSimpleIcon} from "../../assets/icons/regulativa_s.svg";
import {ReactComponent as ShoppingCartIcon} from "./../../assets/icons/cart_icon.svg";
import {textToUrl} from "../GobalFunctions/GlobalFunctions";
import AlertQuantityModal from "../UI/Modals/AlertQuantityModal/AlertQuantityModal";
import SquareBadge from "../UI/SquareBadge/SquareBadge";
import TooltipComponent from "../UI/TooltipComponent/TooltipComponent";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import RelatedArticles from "./RelatedArticles/RelatedArticles";

const ArticleInfoPage = (props) => {

    const [image, imageSetter] = useState();
    const [showAlertModal, showAlertModalSetter] = useState(false);
    const [articleCategoryBreadcrumbs, setArticleCategoryBreadcrumbs] = useState(null);

    useEffect(() => {
        if (props.articleDetails !== null) {
            if (props.articleDetails.jkl === null || props.articleDetails.jkl.trim() === "" || props.articleDetails.itemAvailabilityCode === 'BR') {
                imageSetter(props.articleDetails.pictures !== null ? ConfigData.ArticleImageLink + props.articleDetails.pictures : DefaultImage);
            } else {
                imageSetter(RegulativaIcon)
            }
        }
    }, [props.articleDetails]);

    useEffect(() => {
        return () => {
            props.onFetchArticleInfo(null);
            props.onFetchArticleDetailsForInfoPage(null);
            props.onResetRelatedArticlesForArticle();
            props.onResetRelatedArticlesOnTerapijskaIndikacija();
            props.onResetCategoryRelatedArticles();
            props.onResetFindCategoryForArticle();
        }
    }, [])

    useEffect(() => {
        if (props.match.params.branchMarketplaceId !== undefined &&
            props.match.params.marketplaceId !== undefined) {

            if (props.storeForInfoPageDetails === null ||
                props.storeForInfoPageDetails.marketplaceId !== +props.match.params.marketplaceId ||
                props.storeForInfoPageDetails.branchMarketplaceId !== +props.match.params.branchMarketplaceId) {
                props.onFetchDetailsStoreInfoPage(props.match.params.marketplaceId, props.match.params.branchMarketplaceId);
            }
        }

    }, [])

    useEffect(() => {
        if (props.selectedArticles === null || props.selectedArticles === undefined) {
            let articleId = window.location.pathname.split('/')[3]
            let branchMarketplaceId = window.location.pathname.split('/')[4]
            let marketplaceId = window.location.pathname.split('/')[5]
            props.onFetchSelectedArticle(articleId, branchMarketplaceId, marketplaceId);
        }

        if(props.articleDetails)
        props.onFetchArticleInfo(props.match.params.jkl);
    }, [props.articleDetails])

    // useEffect(() => {
    //         props.onFetchArticleInfo(props.match.params.jkl);
    // }, [props.match.params.jkl])

    useEffect(() => {
        if (props.articleDetails === null && props.match.params.branchMarketplaceId === undefined &&
            props.match.params.marketplaceId === undefined) {
            props.onFetchArticleDetailsForInfoPage(window.location.pathname.split('/')[3]);
        }
    }, [])

    useEffect(() => {
        if (props.articleDetails !== null) {
            if(props.articleDetails.jkl) {
                props.onFetchRelatedArticlesForArticle(props.articleDetails.id);
                props.onFetchRelatedArticlesOnTerapijskaIndikacija(props.articleDetails.id);
            } else {
                props.onFetchCategoryRelatedArticles(props.articleDetails.id)
                props.onFindCategoryForArticle(props.articleDetails.id);
            }
        }
    }, [props.articleDetails])

    useEffect(() => {
        if (props.match.params.marketplaceId !== undefined &&
            props.match.params.branchMarketplaceId !== undefined) {
            if ((props.articleInStore === null) || (props.articleInStore.itemResponse.id !== +props.match.params.id)) {
                props.onFetchArticleInStore(props.match.params.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
            }
        }
    }, [props.match.params.id]);

    useEffect(() => {
        if (props.articleDetails !== null && props.match.params.branchMarketplaceId !== undefined && props.match.params.marketplaceId !== undefined) {
            props.onFetchSelectedArticle(props.articleDetails.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId);
        }
    }, [])

    const addToCart = () => {
        if (props.userInfo !== null) {
            let idsArray = []
            props.shoppingCart && props.shoppingCart.items.map(item => {
                idsArray.push(item.itemResponse.id)
            })
            if (!idsArray.includes(props.articleInStore.itemResponse.id)) {
                props.onAddItemToShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId, props.selectedArticles);
            } else {
                props.shoppingCart.items.map(item => {
                    if (item.itemResponse.id === props.articleInStore.itemResponse.id) {
                        if (item.quantity < item.stockQuantity) {
                            props.onAddItemToShoppingCart(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId, props.selectedArticles);
                        } else {
                            showAlertModalSetter(true)
                        }
                    }
                })
            }
        }
    }

    useEffect(() => {
        if (props.shoppingCart === null) {
            // if (props.userInfo !== null) {
            props.onFetchShoppingCart(localStorage.getItem('id'), window.location.pathname.split('/')[4], window.location.pathname.split('/')[5]);
            // }
        }
    }, [])

    const closeAlertModal = () => {
        showAlertModalSetter(false)
    }

    const searchForArticle = () => {
        let url = '/articles-and-stores/' + props.match.params.id + '/0' + '/' + textToUrl(props.match.params.articleName);
        props.history.push(url);
    }

    let quantityInCart
    if (props.shoppingCart !== null && props.articleDetails !== null) {
        props.shoppingCart.items.map(item => {
            if (item.itemResponse.id === props.articleDetails.id) {
                quantityInCart = item.quantity
            }
        })
    }

    //vuce ime iz infoLek baze
    // let articleName = props.articleInfo !== null && props.articleInfo.registrationName

    //vuce ime iz nase baze
    let articleName = props.articleDetails !== null && props.articleDetails.name
    let articleId = props.articleDetails !== null && props.articleDetails.id

    const [articleDetailsText, articleDetailsTextSetter] = useState();

    useEffect(() => {
        if (props.articleInfo !== null) {
            articleDetailsTextSetter(<div dangerouslySetInnerHTML={{__html: props.articleInfo.indications}}></div>);
        } else if (props.articleDetails && props.articleDetails.itemInfoResponse && props.articleDetails.itemInfoResponse.description !== null) {
            articleDetailsTextSetter(<div dangerouslySetInnerHTML={{__html: props.articleDetails.itemInfoResponse.description}}></div>);
        } else {
            articleDetailsTextSetter(<div>Uskoro više podataka o proizvodu!{props.articleInfo}</div>);
        }
    }, [props.articleInfo, props.articleDetails && props.articleDetails.itemInfoResponse])

    // let articleDetailsText = props.articleInfo !== null || props.articleDetails.itemResponse.description !== null?
    //     <div dangerouslySetInnerHTML={{__html: props.articleInfo.indications}}></div>
    //     : <div>Uskoro više podataka o proizvodu!{props.articleInfo}</div>
    // ;
    // {props.articleDetails.itemResponse.description && dangerouslySetInnerHTML={{__html: props.articleDetails.itemResponse.description}}}

    // formiranje breadcrumbs-a za kategoriju kojoj pripada artikal
    useEffect(()=>{
        if(props.categoryForArticle && props.categoryForArticle.length > 0) {
            let tempBreadcrumbs = props.categoryForArticle.map((category, index) => {
                if (index < props.categoryForArticle.length-1) {
                    return category.name + '/ '
                } else if (index === props.categoryForArticle.length-1) {
                    return category.name
                }
            });
            setArticleCategoryBreadcrumbs(tempBreadcrumbs.join(''))
        }
    }, [props.categoryForArticle])



    const buyingButton =
        <div className={styles.ButtonWrapper}>
            <Button buttontype='whitedarkblue' style={{
                padding: "1.2rem 1.7rem",
                fontWeight: "600",
                fontSize: "16px",
                position: "relative"
            }} onClick={addToCart}>
                <ShoppingCartIcon style={{width: "24px", height:"24px", stroke: "inherit", left: "24px"}}/>&nbsp;&nbsp;
                Dodaj
                {quantityInCart &&
                    <span className={styles.QuantityInCart}>{quantityInCart}</span>
                }
            </Button>
            {props.articleInStore && props.articleInStore.itemPriceWithDiscount !== null && props.articleInStore.itemPriceWithDiscount > 0 &&
                <div className={styles.SqBox}>
                    <SquareBadge
                        backgroundColor={"#FFAE00"}
                        marginLeft={"6px"}
                    >
                        <span style={{color: "white"}}>%</span>
                    </SquareBadge>
                    <TooltipComponent className={styles.TooltipText} text="Artikal je na popustu!"/>
                </div>
            }
        </div>

    const checkAvailabilityButton =
        //Razlicit izgled dugmeta za web i za mobile
        <Media
            queries={{
                small: "(max-width: 699px)",
                large: "(min-width: 700px)"
            }}>
            {matches => (
                <Fragment>
                    {matches.small &&
                        <div className={styles.CheckAvailability}>
                            <Button buttontype='whitedarkblue' style={{
                                width: "100%",
                                padding: "1.5rem 3rem",
                                fontWeight: "600",
                                fontSize: "18px",
                                position: "relative"
                            }} onClick={searchForArticle}>
                                <LocationMarkerIcon style={{width: "24px", color: "inherit", left: "24px"}}/>&nbsp;&nbsp;
                                Proveri dostupnost
                            </Button>
                        </div>
                    }

                    {matches.large &&
                        <div className={styles.CheckAvailability}>
                            <p onClick={searchForArticle}>Proveri dostupnost</p>
                        </div>
                    }
                </Fragment>
            )}
        </Media>

    //todo srediti ovaj upit za to da li je u shopu ili nije
    const inShop = props.storeForInfoPageDetails ?
        (
            // ako u shopu postoji lek
            window.location.pathname.split('/')[4] && window.location.pathname.split('/')[5] && props.articleInStore !== null ?
                <>
                    <div className={styles.ArticlePriceInfo}>
                        {!props.articleInStore.itemResponse.jkl ? <>
                                <div className={styles.Amount}>
                                    <span style={{fontSize: "16px"}}>Cena*:</span>
                                    {props.articleInStore.itemPriceWithDiscount && props.articleInStore.itemPriceWithDiscount > 0 ?
                                        <span style={{fontSize: "18px", color: "#014F86", fontWeight: "bold"}}>
                                            <div className={styles.OriginalPrice}>
                                                 <span>{(props.articleInStore.itemPrice).toFixed(2)}</span>
                                                <span>&nbsp; RSD</span>
                                            </div>
                                         <div style={{display: "flex"}}>
                                                <span>{(props.articleInStore.itemPriceWithDiscount).toFixed(2)}</span>
                                             <span>&nbsp; RSD</span>
                                            </div>
                                    </span>
                                        :
                                        <>
                                        <span style={{fontSize: "18px", color: "#014F86", fontWeight: "bold"}}>
                                            <span>{(props.articleInStore.itemPrice).toFixed(2)}</span>
                                            <span> RSD</span>
                                            </span>
                                        </>
                                    }
                                </div>
                                <div style={{marginBottom: ".5rem", fontSize: "13px", width: "100%"}}>*Cena u
                                    apoteci {props.storeForInfoPageDetails.name}</div>
                            </> :
                            <div className={styles.Amount}>
                                <RegulativaSimpleIcon/>
                                <span>REGULATIVA</span>
                                <ExclamationCircleIcon style={{width: "20px", stroke: "#F58300"}}/>
                            </div>
                        }
                    </div>
                    <div className={styles.BuyingActions}>
                        <Media
                            queries={{
                                small: "(max-width: 699px)",
                                large: "(min-width: 700px)"
                            }}>
                            {matches => (
                                <Fragment>
                                    {matches.small &&
                                    <>
                                        {buyingButton}
                                        <div>
                                            {/*mesto za setovanje kolicine*/}
                                        </div>
                                    </>
                                    }

                                    {matches.large &&
                                    <>
                                        <div>
                                            {/*mesto za setovanje kolicine*/}
                                        </div>
                                        {buyingButton}
                                    </>
                                    }
                                </Fragment>
                            )}
                        </Media>
                    </div>
                </>
                :
                // ako u shopu nema leka
                checkAvailabilityButton
        )
        :
        // ako smo na InfoLek usli iz pretrage lekova, ne sa strane shopa
        checkAvailabilityButton

    // (props.minPrice !== -1 &&
    //     <p style={{marginTop: "2rem", marginBottom: "0rem"}}>Opseg
    //         cena: {props.minPrice} - {props.maxPrice} RSD</p>)

    const exitArticleInfoPage = () => {
        props.history.replace(localStorage.getItem('beforeEnteringInfoLek'));
        localStorage.removeItem('beforeEnteringInfoLek');
    }

    return (
        <>
            <Toolbar/>
            <div className={styles.ArticleInfoPageContainer}>
                <Media
                    queries={{
                        small: "(max-width: 699px)",
                        large: "(min-width: 700px)"
                    }}>
                    {matches => (
                        <Fragment>

                            {matches.small &&
                                <div className={styles.ArticleInfoPageMobileWrapper}>
                                    <HeaderWithTitleAndBackChevron
                                        icon
                                        title="Detalji o proizvodu"
                                        onIconClick={exitArticleInfoPage}/>
                                    <ArticleImageHolder image={image}/>
                                    <div>{articleName}</div>
                                    <div className={styles.ArticleCategoryBreadcrumbs}>
                                        {articleCategoryBreadcrumbs}
                                    </div>
                                    <div>{inShop}</div>
                                    <ArticleInfoLek
                                        articleInfo={props.articleInfo}
                                    />
                                </div>
                            }

                            {matches.large &&
                                <>
                                    <LiveChatButton/>
                                    <div className={styles.ArticleInfoPageContentWrapper}>
                                        <div className={styles.PictureAndGeneralInfoBox}>
                                            <ArticleImageHolder image={image}/>
                                            <ArticleDetailsHolder title={articleName}
                                                                  id={articleId}
                                                                  details={articleDetailsText}/>
                                            <ArticleActionsHolder article={props.articleDetails}>
                                                {inShop}
                                            </ArticleActionsHolder>
                                        </div>
                                        <div>
                                            {(props.relatedArticles && props.relatedArticles.length > 0 || props.relatedArticlesOnTerapijskaIndikacija && props.relatedArticlesOnTerapijskaIndikacija.length > 0
                                                || props.categoryRelatedArticles && props.categoryRelatedArticles.length > 0) &&
                                                <Tabs>

                                                    <div className={styles.TabHolder}>
                                                        <TabList className={styles.TabList}>
                                                            {props.relatedArticlesOnTerapijskaIndikacija && props.relatedArticlesOnTerapijskaIndikacija.length > 0 &&
                                                                <Tab selectedClassName={styles.ActiveTab}>Artikli povezani po terapijskoj indikaciji</Tab>}
                                                            {props.relatedArticles && props.relatedArticles.length > 0 &&
                                                                <Tab selectedClassName={styles.ActiveTab}>Artikli bazirani na istoj supstanci</Tab>}
                                                            {props.categoryRelatedArticles && props.categoryRelatedArticles.length > 0 &&
                                                                <div style={{display:"flex", alignItems:"baseline"}}>
                                                                    <Tab selectedClassName={styles.ActiveTab}>Artikli iz
                                                                        iste kategorije: </Tab>
                                                                   <span style={{position:"relative", bottom:"-1px"}}>{articleCategoryBreadcrumbs}</span>
                                                                </div>
                                                            }
                                                        </TabList>
                                                    </div>


                                                    {props.relatedArticlesOnTerapijskaIndikacija && props.relatedArticlesOnTerapijskaIndikacija.length > 0 &&
                                                        <TabPanel>
                                                            <RelatedArticles therapyIndication/>
                                                        </TabPanel>
                                                    }

                                                    {props.relatedArticles && props.relatedArticles.length > 0 &&
                                                        <TabPanel>
                                                            <RelatedArticles mainSubstance/>
                                                        </TabPanel>
                                                    }

                                                    {props.categoryRelatedArticles && props.categoryRelatedArticles.length > 0 &&
                                                        <TabPanel>
                                                            <RelatedArticles sameCategory/>
                                                        </TabPanel>
                                                    }

                                                </Tabs>
                                            }
                                            <ArticleInfoLek
                                                articleInfo={props.articleInfo}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        </Fragment>
                    )}
                </Media>
                {showAlertModal &&
                    <AlertQuantityModal
                        closeAlertQuantityModal={closeAlertModal}
                        show={showAlertModal}
                        backdropClickHandler={closeAlertModal}/>
                }
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        articleInfo: state.articleInfoPage.articleInfo,
        articleInfoError: state.articleInfoPage.articleInfoError,
        loadingArticleInfo: state.articleInfoPage.loadingArticleInfo,

        articleDetails: state.articleInfoPage.articleDetailsForInfoPage,
        minPrice: state.appPage.minPrice,
        maxPrice: state.appPage.maxPrice,

        articleInStore: state.articleInfoPage.articleInStore,
        articleInStoreError: state.articleInfoPage.articleInStoreError,
        loadingArticleInStore: state.articleInfoPage.loadingArticleInStore,

        storeForInfoPageDetails: state.articleInfoPage.storeForInfoPageDetails,
        storeForInfoPageDetailsError: state.articleInfoPage.storeForInfoPageDetailsError,
        loadingStoreForInfoPageDetails: state.articleInfoPage.loadingStoreForInfoPageDetails,

        selectedArticles: state.shopPage.selectedArticles,
        selectedArticlesFailed: state.shopPage.selectedArticlesFailed,
        loadingSelectedArticles: state.shopPage.loadingSelectedArticles,

        userInfo: state.authPage.userInfo,

        shoppingCart: state.shopPage.shoppingCart,

        relatedArticles: state.articleInfoPage.relatedArticles,
        relatedArticlesError: state.articleInfoPage.relatedArticlesError,
        loadingRelatedArticles: state.articleInfoPage.loadingRelatedArticles,

        relatedArticlesOnTerapijskaIndikacija: state.articleInfoPage.relatedArticlesOnTerapijskaIndikacija,
        relatedArticlesOnTerapijskaIndikacijaError: state.articleInfoPage.relatedArticlesOnTerapijskaIndikacijaError,
        loadingRelatedArticlesOnTerapijskaIndikacija: state.articleInfoPage.loadingRelatedArticlesOnTerapijskaIndikacija,

        categoryRelatedArticles: state.articleInfoPage.categoryRelatedArticles,
        loadingCategoryRelatedArticles: state.articleInfoPage.loadingCategoryRelatedArticles,
        categoryRelatedArticlesError: state.articleInfoPage.categoryRelatedArticlesError,

        categoryForArticle: state.articleInfoPage.categoryForArticle,
        loadingCategoryForArticle: state.articleInfoPage.loadingCategoryForArticle,
        categoryForArticleError: state.articleInfoPage.categoryForArticleError
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchArticleInfo: (articleJKL) => dispatch(actions.fetchArticleInfo(articleJKL)),
        onFetchArticleDetailsForInfoPage: (articleId) => dispatch(actions.fetchArticleDetailsForInfoPage(articleId)),

        onFetchRelatedArticlesForArticle: (articleId) => dispatch(actions.fetchRelatedArticlesForArticle(articleId)),
        onFetchRelatedArticlesOnTerapijskaIndikacija: (articleId) => dispatch(actions.fetchRelatedArticlesOnTerapijskaIndikacija(articleId)),

        onFetchArticleInStore: (selectedArticleId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchArticleInStore(selectedArticleId, branchMarketplaceId, marketplaceId)),
        onFetchDetailsStoreInfoPage: (marketplaceId, branchMarketplaceId) => dispatch(actions.fetchDetailsForStoreInfoPage(marketplaceId, branchMarketplaceId)),


        onAddItemToShoppingCart: (userId, branchMarketplaceId, marketplaceId, item) => dispatch(actions.addItemToShoppingCart(userId, branchMarketplaceId, marketplaceId, item)),
        onFetchSelectedArticle: (selectedArticleId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedArticle(selectedArticleId, branchMarketplaceId, marketplaceId)),

        onFetchShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchShoppingCart(userId, branchMarketplaceId, marketplaceId)),

        onFetchCategoryRelatedArticles: (articleId) => dispatch(actions.fetchCategoryRelatedArticles(articleId)),

        onResetCategoryRelatedArticles: () => dispatch(actions.categoryRelatedArticles(null)),
        onResetRelatedArticlesOnTerapijskaIndikacija: () => dispatch(actions.relatedArticlesOnTerapijskaIndikacija(null)),
        onResetRelatedArticlesForArticle: ()=> dispatch(actions.relatedArticlesForArticle(null)),

        onFindCategoryForArticle: (articleId) => dispatch(actions.findCategoryForArticle(articleId)),
        onResetFindCategoryForArticle: () => dispatch(actions.findCategoryForArticle(null))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArticleInfoPage));