import React, {useEffect, useState} from 'react'
import * as actions from "../../../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import PrescriptionImageThumbnail from "./PrescriptionImageThumbnail/PrescriptionImageThumbnail";
import Spinner from "../../../../UI/Spinner/Spinner";
import Modal from "../../../../UI/Modals/Modal/Modal";
import Backdrop from "../../../../UI/Backdrop/Backdrop";
import Button from '../../../../UI/Buttons/Button/Button'

const PrescriptionImagesThumbnails = props => {

    const [isPreviewModalOpen, isPreviewModalOpenHandler] = useState(false);
    const [bigPhotoLink, bigPhotoLinkSetter] = useState(null);

    let size
    useEffect(()=>{
        size = prescriptionImagesThumbnails[0].length;
        props.getSize(size);
    })

    let prescriptionImagesThumbnails = <Spinner/>;

    const deletePrescriptionImage = (prescriptionImageId) => {
        props.onDeletePrescription(props.userInfo.id, props.match.params.branchMarketplaceId, props.match.params.marketplaceId, prescriptionImageId);
    }

    const openPreviewModal =  (imageLink) => {
        bigPhotoLinkSetter('https://apoteka.rs/cdn/prescriptions_images/' + imageLink)
        isPreviewModalOpenHandler(true);
    }

    if (props.shoppingCart !== null) {
            prescriptionImagesThumbnails = [props.shoppingCart.prescriptionsLinks.map((prescriptionLink, index) => {
                return (
                    <PrescriptionImageThumbnail prescriptionLink={prescriptionLink}
                                                key={index}
                                                deletePrescriptionImage={()=> deletePrescriptionImage(prescriptionLink.id)}
                                                openPreviewModal={()=> openPreviewModal(prescriptionLink.imageLink)}
                    />
                );
            })]
    }

    return (
        <>
            {prescriptionImagesThumbnails}

            {isPreviewModalOpen &&
                <Modal style={{
                    width: "50vw",
                    height: "70vh",
                    padding: "30px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent:"center",
                    alignItems:"center",
                    boxSizing:"border-box",
                    position:"fixed",
                    top:"calc(50% - 35vh)",
                    left:"calc(50% - 25vw)"
                }}>
                    <img src={bigPhotoLink} alt="fotografija recepta" style={{maxWidth:"100%", maxHeight:"90%"}}/>
                    <br/>
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <Button buttontype='blue' onClick={()=>isPreviewModalOpenHandler(false)}>Zatvori</Button>
                    </div>
                </Modal>
            }
            <Backdrop show={isPreviewModalOpen}
                      backdropClickHandler={()=>isPreviewModalOpenHandler(false)}
                      zIndex={15}
                      backgroundColor="rgba(0, 0, 0, .2)"/>
        </>
    )


}

const mapStateToProps = (state) => {
    return {
        selectedStore: state.appPage.selectedStore,

        shoppingCart: state.shopPage.shoppingCart,

        userProfile: state.userProfile.userProfile,
        userProfileError: state.userProfile.userProfileError,
        loadingUserProfile: state.userProfile.loadingUserProfile,

        userInfo: state.authPage.userInfo,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSelectedStore: (branchMarketplaceId, marketplaceId) => dispatch(actions.fetchSelectedStore(branchMarketplaceId, marketplaceId)),
        onFetchUserProfile: (email) => dispatch(actions.fetchUserProfile(email)),
        onFetchShoppingCart: (userId, branchMarketplaceId, marketplaceId) => dispatch(actions.fetchShoppingCart(userId, branchMarketplaceId, marketplaceId)),
        onDeletePrescription: (userId, branchMarketplaceId, marketplaceId, prescriptionId) => dispatch(actions.deletePrescription(userId, branchMarketplaceId, marketplaceId, prescriptionId))

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PrescriptionImagesThumbnails))