import React, {useState} from 'react';
import styles from './SingleTherapy.module.css';
import {ReactComponent as TrashIcon} from "../../../../assets/icons/trashIcon.svg";
import {ReactComponent as EditIcon} from "../../../../assets/icons/edit_icon.svg";
import {ReactComponent as DrIcon} from "../../../../assets/icons/doctor_icon.svg";
import * as actions from "../../../../store/actions";
import {connect} from "react-redux";
import ConfirmationModal from "../../../UI/Modals/ConfirmationModal/ConfirmationModal";
import SponsoredTherapyImagesLink from "../../../../config.json";

const SingleTherapy = (props) => {

    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const selectTherapy = (index) => {
        localStorage.setItem('selectedTherapyIndex', index);
        props.therapySelectedCallback(props.therapy, props.index);
    }

    const deleteTherapy = () => {
        props.onDeleteUserTherapy(props.therapy.id);
        setConfirmModalVisible(false);
        props.removeArticles();
    }

    const closeConfirmModal = () => {
        setConfirmModalVisible(false)
    }

    const openConfirmModal = (e) => {
        e.stopPropagation();
        setConfirmModalVisible(true)
    }

    const editTherapy = (e) => {
        e.stopPropagation();
        props.editTherapy(props.therapy, props.index);
    }

    return (
        <div className={styles.SingleTherapyWrapper}>
            <div className={styles.SingleTherapy} style={props.style} onClick={() => selectTherapy(props.index)}>
                {props.therapy.authorizedMedic
                    ? <span className={styles.TherapyName}>
                        <DrIcon
                            style={{paddingRight: "16px"}}/> Terapija {props.therapy.name}
                      </span>
                    :
                    props.therapy.iconImage
                        ? <div className={styles.SponsoredTherapy}>
                            <span>{props.therapy.name}</span>
                            <img
                                src={props.commercial !== null && SponsoredTherapyImagesLink.SponsoredTherapyImagesLink + props.therapy.iconImage}
                                alt="" style={{width: "110px"}}/>
                        </div>
                        : <span>{props.therapy.name}</span>
                }
                {!props.therapy.authorizedMedic && !props.therapy.promotion &&
                    <div className={styles.ActionsWrapper}>
                        <EditIcon className={styles.IconStyle} onClick={editTherapy}/>
                        <TrashIcon className={styles.IconStyle} onClick={e =>openConfirmModal(e)}/>
                    </div>
                }
                {props.therapy.authorizedMedic && (props.therapy.status === 'EXPIRED' || props.therapy.status === 'USED') &&
                    <div className={styles.ActionsWrapper}>
                        <TrashIcon className={styles.IconStyle} onClick={e => openConfirmModal(e)}/>
                    </div>
                }
            </div>
            {confirmModalVisible &&
                <ConfirmationModal
                    show={confirmModalVisible}
                    backdropClickHandler={props.onCloseClick}
                    text={"Da li ste sigurni da želite da izbrišete terapiju: " + props.therapy.name}
                    denyButton={closeConfirmModal}
                    confirmButton={deleteTherapy}
                />
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        // userTherapies: state.userProfile.userTherapies
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onDeleteUserTherapy: (therapyId) => dispatch(actions.deleteUserTherapy(therapyId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleTherapy);