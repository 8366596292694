import React from 'react'
import styles from './VideoCallPage.module.css'
import Toolbar from "../Navigation/Toolbar/Toolbar";
import VideoCall from "./VideoCallFunction/VideoCall";
import Wrapper from "../UI/Wrapper/Wrapper";

const VideoCallPage = () => {


    return (
        <>
            <Toolbar/>
            <Wrapper>
                <div className={styles.VideoCallPage}>

                    <VideoCall/>
                </div>
            </Wrapper>
        </>
    )
}

export default VideoCallPage;