import React, {Fragment, useEffect, useRef, useState} from 'react';
import PicturePlacer from './PicturePlacer/PicturePlacer'
import styles from './MainPage.module.css'
import {connect} from "react-redux";
import * as actions from "../../store/actions";
import Wrapper from "../UI/Wrapper/Wrapper";
import Media from "react-media";
// import YouTubeComponent from "../YouTubeComponent/YouTubeComponent";
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import AppStoreModal from "../UI/Modals/AppStoreModal/AppStoreModal";
import RateTransactionModal from "../UI/Modals/RateTransactionModal/RateTransactionModal";
import PlayStoreBoard from "./PlayStoreBorad/PlayStoreBoard";
import Section from "../UI/Section/Section";
import Image1 from '../../assets/onama-2 2.png'
import Button from "../UI/Buttons/Button/Button";
import BulletinBoard from "./Billboard/BulletinBoard";
import configData from "../../configConstants.json";
import bulletImage from "../../assets/aboutus-1 7.png"

const MainPage = (props) => {


   const [showBackToTopState, showBackToTopStateChanged] = useState({
       showBackToTop: false
   })

    const [showAppStoreModal, showAppStoreModalSetter] = useState(false)
    const [appStoreModal, appStoreModalSetter] = useState(+localStorage.getItem('AppModalCounter') || 0)

    const [showRateOrderModal, showRateOrderModalSetter] = useState(false);

    const [day, setDay] = useState();
    const [month, setMonth] = useState();
    const [year, setYear] = useState();

    //useEffect za brisanje izabranih filtera prilikom povratka na pocetnu stranu
    useEffect(() => {
        for (let key in localStorage){
            if (key.includes('storeFilter')) localStorage.removeItem(key)
        }
        props.onStoreFilterSelection({inMyRadius: false, filters: ''});
    }, [])


    //Ovaj deo sluzi da funkcionalnost strelice koja vraca na vrh stranice
    useEffect(() => {
        props.onToolbarChanged(false)
        window.onscroll = function() {
            if(window.pageYOffset !== 0) {
                showBackToTopStateChanged({
                    showBackToTop: true
                })
            }
            if(window.pageYOffset === 0) {
                showBackToTopStateChanged({
                    showBackToTop: false
                })
            }
        };


        //Poziva akciju koja uzima sa back-a informacije o broju
        //apoteka i broju artikala u sistemu
        props.onCountMarketplaces();

        //uzima sa bakc-a random reklamu
        props.onFetchRandomAdvertisement();

        //resetuje search placeholder
        props.onSearchBarPlaceholderReset();

    },[])

    useEffect(()=> {
        if(+appStoreModal < 2 && (isAndroid || (isMobile && isIOS))) {
            if (!sessionStorage.getItem('AppModal')) {
                let temp = appStoreModal;
                appStoreModalSetter(temp + 1)
                localStorage.setItem('AppModalCounter', temp + 1)
                sessionStorage.setItem('AppModal', 'shown')
                showAppStoreModalSetter(true);
            }
        }

    }, [])

    useEffect(() => {
        showRateOrderModalSetter(true)
    }, []);

    useEffect(() => {
        if (props.rating !== null) {
            let date = new Date(props.rating.created);
            setDay(date.getDate());
            setMonth(date.getMonth() + 1);
            setYear(date.getFullYear());
        }
    }, [props.rating])

    const fieldRef = useRef(null);
    const backToTopHandler = () => {
        fieldRef.current.scrollIntoView({
            behavior: "smooth",
            // block: 'nearest',
            // inline: 'start'
        });
    }

    const saveRating = (rateValue) => {
        showRateOrderModalSetter(false);
        const rating = {
            ratingId: props.rating.id,
            rating: rateValue,
            ratingComment: ''
        }
        props.onRateTransaction(rating);
    }

    const redirectToAboutUs = () => {
        const url = '/AboutUs';
        props.history.push(url);
    }

    const redirectToBecomeAPartner= () => {
        const url = '/BecomeAPartner';
        props.history.push(url);
    }
    const redirectToMarketingPage = () => {
        const url = '/Marketing';
        props.history.push(url);
    }

    const redirectToSupportPage = () => {
        const url = '/AboutUs';
        props.history.push(url);
    }

    return (
        <>
            {showAppStoreModal && <AppStoreModal onCloseClick={() => showAppStoreModalSetter(false)} />}
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>

                        {matches.small &&
                            <>
                                <PicturePlacer/>
                                {/*<YouTubeComponent/>*/}
                            </>
                        }

                        {matches.large &&
                            <div className={styles.MainPage}>
                                <Wrapper>
                                    <div ref={fieldRef}></div>
                                    <PicturePlacer backToTop={backToTopHandler}/>

                                    {/*//************* disableovano za prvu verziju projekta ****************/}
                                    {/*<Section imageSrc={Image1} withMargin*/}
                                    {/*         Content={*/}
                                    {/*    <div className={styles.SectionAboutUs}>*/}
                                    {/*        <Button buttontype="blue" onClick={redirectToAboutUs}*/}
                                    {/*                style={{height: "32px", position:"absolute", top:"64px", textAlign:"center"}}>O nama</Button>*/}
                                    {/*        <h3 style={{fontSize:"24px"}}>Mi smo apoteka.rs</h3>*/}
                                    {/*        <p> apoteka.rs čini potragu za lekom lakšom i bržom, omogućava da se proizvodi rezervišu i preuzmu u*/}
                                    {/*            terminu kada to tebi odgovara, pruža mogućnost pretrage i rezervacije više proizvoda odjednom, kao i*/}
                                    {/*            pronalazak i rezervaciju celokupne RFZO terapije u jednoj apoteci.</p>*/}
                                    {/*    </div>*/}
                                    {/*         }*/}
                                    {/*/>*/}
                                    {/*<PlayStoreBoard/>*/}
                                    {/*<div style={{width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", color:"#454952"}}>*/}
                                    {/*    <h3 style={{fontSize:"44px", width:"50%", textAlign:"center"}}>Zašto da postaneš partner platforme apoteka.rs</h3>*/}
                                    {/*    <p style={{fontSize:"28px"}}>Veza između korisnika i apoteka, u cilju što brže i efikasnije pomoći pacijentima.</p>*/}
                                    {/*</div>*/}
                                    {/*<BulletinBoard*/}
                                    {/*    bullet1Image={bulletImage}*/}
                                    {/*    bullet1Title={configData.MainPage.BulletinBoard.FirstBulletTitle}*/}
                                    {/*    bullet1Text={configData.MainPage.BulletinBoard.FirstBulletText}*/}
                                    {/*    bullet1onClick={redirectToBecomeAPartner}*/}
                                    {/*    bullet2Image={bulletImage}*/}
                                    {/*    bullet2Title={configData.MainPage.BulletinBoard.SecondBulletTitle}*/}
                                    {/*    bullet2Text={configData.MainPage.BulletinBoard.SecondBulletText}*/}
                                    {/*    bullet2onClick={redirectToMarketingPage}*/}
                                    {/*    bullet3Image={bulletImage}*/}
                                    {/*    bullet3Title={configData.MainPage.BulletinBoard.ThirdBulletTitle}*/}
                                    {/*    bullet3Text={configData.MainPage.BulletinBoard.ThirdBulletText}*/}
                                    {/*    bullet3onClick={redirectToMarketingPage}*/}
                                    {/*/>*/}

                                    {/*{showBackToTopState.showBackToTop &&*/}
                                    {/*<div onClick={backToTopHandler} className={styles.BackToTop}>*/}
                                    {/*    <button ><ArrowUpIcon/></button>*/}
                                    {/*</div>}*/}
                                    {/*//************* ./   disableovano za prvu verziju projekta ****************/}
                                    {/*<ChooseLocation />*/}
                                    {/*    <YouTubeComponent/>*/}
                                </Wrapper>
                            </div>
                        }
                    </Fragment>
                )}
            </Media>
            {showRateOrderModal && props.rating &&
                <RateTransactionModal
                    show={showRateOrderModal}
                    onRateButtonClick={saveRating}
                    shopName={props.rating.marketplaceResponse.name}
                    orderNumber={props.rating.orderNumber}
                    day={day}
                    month={month}
                    year={year}/>
            }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        rating: state.mainPage.rating
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onToolbarChanged: (changed) => dispatch(actions.toolbarChanged(changed)),
        onCountMarketplaces: () => dispatch(actions.countMarketplacesAction()),
        onFetchRandomAdvertisement: () => dispatch(actions.fetchRandomAdvertisement()),
        onSearchBarPlaceholderReset: () => dispatch(actions.searchBarPlaceholderChanged('')),
        onStoreFilterSelection: (storeFilter) => dispatch(actions.storeFilterSelection(storeFilter)),
        onRateTransaction: (rating) => dispatch(actions.rateTransaction(rating))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
