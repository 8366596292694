import * as actionTypes from './actionsTypes';
import axios from 'axios'
import apotekaAxios, {baseUrl} from '../../axios/axios-apoteka'

//login actions
export const loginStart = () => {
    return {
        type:actionTypes.LOGIN_USER_START
    };
};

export const loginSuccess = (token) => {
    return {
        type: actionTypes.LOGIN_USER_SUCCESS,
        token: token
    };
};

export const loginFail = (error) => {
    return {
        type:actionTypes.LOGIN_USER_FAILED,
        error: error
    };
};

export const loginRefreshFailed = () => {
    return {
        type:actionTypes.LOGIN_REFRESH_FAILED
    };
};

export const loginServerError = () => {
    return {
        type:actionTypes.LOGIN_SERVER_ERROR
    };
};

export const registrationUnconfirmed = () => {
    return {
        type:actionTypes.REGISTRATION_UNCONFIRMED,
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('email');
    localStorage.removeItem('id');
    localStorage.removeItem('firstName');
    localStorage.removeItem('secondName');
    localStorage.removeItem('firstNameForOrder');
    localStorage.removeItem('secondNameForOrder');
    localStorage.removeItem('phoneNumber');
    // localStorage.removeItem('userPhoto');
    localStorage.removeItem('LBO');
    localStorage.removeItem('roles');
    localStorage.removeItem('color');
    localStorage.removeItem('addressSelected');
    localStorage.removeItem('deliveryMethod');
    localStorage.removeItem('selectedAddress');
    localStorage.removeItem('totalPrice');
    localStorage.removeItem('selectedShopFilter');
    localStorage.removeItem('isGoogleLogin');
    localStorage.removeItem('haveDrugInCart');
    localStorage.removeItem('deletePhoneNumberAfterOrder');
    localStorage.removeItem('branchMarketplaceId');
    localStorage.removeItem('marketplaceId')
    localStorage.removeItem('beforeEnteringStore')
    localStorage.removeItem('searchRoute')

    return {
        type: actionTypes.LOGOUT_USER
    }
}

export const checkLoginTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            if (!(localStorage.getItem('refreshToken'))) {
                console.log("RefreshToken")
                dispatch(logout());
            }
            else {
                dispatch(login(null, 0, JSON.parse(localStorage.getItem('isGoogleLogin')), true));
            }
        }, expirationTime * 1000);
    }
}


export const loginCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            console.log("token obican")
            dispatch(logout());
        }
        else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()){
                console.log("expirationDate")
                localStorage.removeItem('token');
                dispatch(login(null, 0, JSON.parse(localStorage.getItem('isGoogleLogin')), true));
            }
            else {
                dispatch(loginSuccess(token));
                dispatch(userInfo({
                    id: +localStorage.getItem('id'),
                    username: localStorage.getItem('email'),
                    firstName: localStorage.getItem('firstName'),
                    secondName: localStorage.getItem('secondName'),
                    // userPhoto: localStorage.getItem('userPhoto') === "null" ? null : localStorage.getItem('userPhoto'),
                    LBO: localStorage.getItem('LBO'),
                    roles: localStorage.getItem('roles').split(',')
                }))
                dispatch(checkLoginTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}

export const login = (credentials, data=0, googleLogin=false, refreshLogin=false) => {
    return dispatch => {

        dispatch(loginStart());

        //Disable for deploy
        // const url = 'http://localhost:8081/auth/oauth/token';

        //Enable for deploy
        // const url = 'https://apoteka.rs:8443/authorization_server/oauth/token';

        //Enable for deploy for dev
        const url = 'https://apoteka.rs:8443/authorization_server/oauth/token';

        //Colors for my profile background on toolbar
        //TODO upisati boje u bazu da bi se prilikom reload-a stranice zadrzala ista boja. Trenutno se boja gubi
        const colors = {
            BLUE: '#00B2B1',
            YELLOW: '#FFAE00',
            ORANGE: '#F58300',
            BROWN: '#4D5055',
            DARK_BLUE: '#013A63',
        }
        let rand = Math.floor(Math.random() * Object.keys(colors).length);

        const params = new URLSearchParams();
        if (refreshLogin) {
            params.append('grant_type', 'refresh_token');
            params.append('refresh_token', localStorage.getItem('refreshToken'));
        }
        else {
            params.append('grant_type', 'password');
            params.append('username', credentials.email);
            params.append('password', credentials.password);
        }

        axios.post(url, params,{
            auth: {
                username: 'apoteka_rs',
                password: 'F7Q8T97@a\'QveBX?'
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(response => {
                let inRegistration = false;
                response.data.userAuthorities.forEach((role) => {
                    if (role === 'REGISTRATION_USER') {
                        inRegistration = true;
                        dispatch(registrationUnconfirmed());
                    }
                })

                if (inRegistration === false) {
                    const expirationDate = new Date (new Date().getTime() + response.data.expires_in * 1000);

                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('refreshToken', response.data.refresh_token);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('id', response.data.userDetails.id);
                    localStorage.setItem('email', response.data.userDetails.username);
                    if(response.data.userDetails.firstName) {
                        localStorage.setItem('firstName', response.data.userDetails.firstName);
                    }
                    if(response.data.userDetails.secondName) {
                        localStorage.setItem('secondName', response.data.userDetails.secondName);
                    }
                    // localStorage.setItem('userPhoto', response.data.userDetails.userPhoto);
                    localStorage.setItem('LBO', response.data.userDetails.lbo);
                    localStorage.setItem('roles', response.data.userAuthorities);
                    localStorage.setItem('isGoogleLogin', JSON.stringify(googleLogin))
                    localStorage.setItem('color', colors[Object.keys(colors)[rand]]);
                    if(response.data.userDetails.phoneNumber !== null )
                    localStorage.setItem('phoneNumber', response.data.userDetails.phoneNumber)
                    dispatch(userInfo({
                        id: response.data.userDetails.id,
                        username: response.data.userDetails.username,
                        password: "",
                        firstName: response.data.userDetails.firstName,
                        secondName: response.data.userDetails.secondName,
                        phoneNumber: response.data.userDetails.phoneNumber,
                        LBO: response.data.userDetails.lbo,
                        // userPhoto: response.data.userDetails.userPhoto,
                        roles: response.data.userAuthorities}));
                    dispatch(loginSuccess(response.data.access_token));
                    dispatch(checkLoginTimeout(response.data.expires_in));
                    // dispatch(fetchUserInfo(response.data));
                }

            })
            .catch(error => {
                if ( error.response !== null && error.response !== undefined) {
                    if (!refreshLogin && error.response.data.error_description === 'Bad credentials') {
                        dispatch(loginFail(error.response.data));
                    } else if (refreshLogin && error.response.data.error === "invalid_token" && error.response.data.error_description.startsWith("Invalid refresh token")) {
                        dispatch(loginRefreshFailed());
                        console.log("Invalid refresh token")
                        dispatch(logout());
                    } else{
                        dispatch(loginServerError());
                    }
                }
            })
            .finally(() => {
                if (data !== 0) {
                    dispatch(updateUserPass({username: credentials.email, password: data}));
                }
            })
    };
};



//user actions
export const userInfo = (userInfo) => {
    return {
        type: actionTypes.FETCH_USER_INFO,
        userInfo: userInfo
    };
};

export const fetchUserInfoStart = () => {
    return {
        type: actionTypes.FETCH_USER_INFO_START
    };
};

export const fetchUserInfoFailed = (error) => {
    return {
        type: actionTypes.FETCH_USER_INFO_FAILED,
        error: error
    };
};

export const fetchUserInfo = () => {
    return dispatch => {
        dispatch(fetchUserInfoStart());
        axios.get(baseUrl + '/user/' + localStorage.getItem('email'), {params: {access_token: localStorage.getItem('token')}})
            .then(response => {
                dispatch(userInfo(response.data));
            })
            .catch(error => {
                dispatch(fetchUserInfoFailed(error));
            })
    };
};


export const forgetPasswordStart = () => {
    return {
        type:actionTypes.FORGET_PASSWORD_START
    };
};

export const forgetPasswordSuccess = () => {
    return {
        type: actionTypes.FORGET_PASSWORD_SUCCESS
    };
};

export const forgetPasswordFail = (error) => {
    return {
        type:actionTypes.FORGET_PASSWORD_FAILED,
        error: error
    };
};

export const forgetPassword = (email) => {
    return dispatch => {
        dispatch(forgetPasswordStart());
        const URL = '/authorization/forget_password/' + email;
        apotekaAxios.get(URL)
            .then(response => {
                dispatch(forgetPasswordSuccess())
                console.log(response.data);
            })
            .catch(error => {
                console.log("Error se desio" + error);
                dispatch(forgetPasswordFail(error));
            })

    };
}

//registration actions
export const registrationStart = () => {
    return {
        type:actionTypes.REGISTER_USER_START
    };
};

export const registrationSuccess = () => {
    return {
        type:actionTypes.REGISTER_USER_SUCCESS
    };
};

export const registrationFail = (error) => {
    return {
        type:actionTypes.REGISTER_USER_FAILED,
        error: error
    };
};

export const registrationServerError = () => {
    return {
        type:actionTypes.REGISTRATION_SERVER_ERROR
    };
};

export const registration = (userData) => {
    return dispatch => {
        dispatch(registrationStart());
        apotekaAxios.post('/authorization/register', userData)
            .then(response => {
                console.log(response);
                dispatch(registrationSuccess());
            })
            .catch(error => {
                if ( error.response !== null && error.response !== undefined && error.response.data === "Already exist") {
                    dispatch(registrationFail(error.response.data));
                }
                else {
                    dispatch(registrationServerError());
                }
            })
    };
};

export const authenticateGoogleUser = (userData) => {
    return dispatch => {
        apotekaAxios.post('/authorization/google', userData)
            .then(response => {

                if (response.status === 200) {
                    let data = response.data;
                    dispatch(login({email: userData.username, password: userData.password}, data, true));

                }
                if (response.status === 201) {
                    dispatch(login({email: userData.username, password: userData.password}, 0, true));
                }
            })
            .catch(error => {
                console.log(error);
            })
    };
};

export const updateUserPass = (userData) => {
    return dispatch => {
        apotekaAxios.post('/authorization/google/update', userData)
            .then(response => {
                console.log(response);
            })
            .catch(error => {
                console.log(error);
            })
    };
};

//google reCaptcha actions
export const googleRecaptchaVerification = (googleRecaptchaResponse) => {
    return {
        type: actionTypes.GOOGLE_RECAPTCHA_VERIFICATION,
        googleRecaptchaResponse: googleRecaptchaResponse
    };
};

export const googleRecaptchaVerificationStart = () => {
    return {
        type: actionTypes.GOOGLE_RECAPTCHA_VERIFICATION_START
    };
};

export const googleRecaptchaVerificationFailed = () => {
    return {
        type: actionTypes.GOOGLE_RECAPTCHA_VERIFICATION_FAILED
    };
};

export const sendReCaptchaToken = (token) => {
    return dispatch => {
        dispatch(googleRecaptchaVerificationStart());
        const URL = '/authorization/re_captcha/' + token
        apotekaAxios.post(URL)
            .then(response => {
                console.log(response.data);
                dispatch(googleRecaptchaVerification(response.data));
            })
            .catch(error => {
                dispatch(googleRecaptchaVerificationFailed());
            })
    };
};

export const PPTOSModalOpenHandler = (PPTOSModalOpened) => {
    return {
        type: actionTypes.PP_TOS_MODAL_HANDLER,
        PPTOSModalOpened: PPTOSModalOpened

    };
};


