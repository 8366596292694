import React, {Fragment} from 'react'
import styles from './OrderCompletionPage.module.css'
import Toolbar from "../../Navigation/Toolbar/Toolbar";
import Stepline from '../OrderCompletionPage/0-Stepline/Stepline'
import {Route} from 'react-router-dom'
import CartSummary from "./1-CartSummary/CartSummary";
import DeliveryOptions from "./2-DeliveryOptions/DeliveryOptions";
import AddressSelection from "./3-AddressSelection/AddressSelection";
import SummaryAndConfirmation from "./4-SummaryAndConfirmation/SummaryAndConfirmation";
import OrderCompletionMessagePage from "../OrderCompletionMessagePage/OrderCompletionMessagePage";
import Wrapper from "../../UI/Wrapper/Wrapper";
import LiveChatButton from "../../UI/LiveChatButton/LiveChatButton";
import Media from "react-media";
import DeliveryOptionsMobile from "./2-DeliveryOptions/DeliveryOptionsMobile";
import UserInfoMobile from "./3-AddressSelection/UserInfoMobile/UserInfoMobile";
import AddressSelectionMobile from "./3-AddressSelection/AddressSelectionMobile/AddressSelectionMobile";

const OrderCompletionPage = () => {

    return (
        <Wrapper>
            <Toolbar/>
            <Media
                queries={{
                    small: "(max-width: 699px)",
                    large: "(min-width: 700px)"
                }}>
                {matches => (
                    <Fragment>
                        {matches.small &&
                        <>
                            <Route path="/shoppingCart/:branchMarketplaceId/:marketplaceId/:branchMarketplaceName/:marketplaceName"
                                   component={CartSummary}/>
                            <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/delivery/:branchMarketplaceName/:marketplaceName"
                                   component={DeliveryOptionsMobile}/>
                            <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/userInfo/:branchMarketplaceName/:marketplaceName"
                                   component={UserInfoMobile}/>
                            <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/addresses/:branchMarketplaceName/:marketplaceName"
                                   component={AddressSelectionMobile}/>
                            <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/confirmation/:branchMarketplaceName/:marketplaceName"
                                   component={SummaryAndConfirmation}/>
                            <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/confirmation-message/:totalLoyaltyPoints/:branchMarketplaceName/:marketplaceName"
                                   component={OrderCompletionMessagePage}/>
                        </>
                        }

                        {matches.large &&
                        <>
                            <LiveChatButton/>
                            <div className={styles.OrderCompletionPage}>
                                {!window.location.pathname.includes('confirmation-message') && <Stepline/>}

                                <Route path="/shoppingCart/:branchMarketplaceId/:marketplaceId/:branchMarketplaceName/:marketplaceName"
                                       component={CartSummary}/>
                                <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/delivery/:branchMarketplaceName/:marketplaceName"
                                       component={DeliveryOptions}/>
                                <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/addresses/:branchMarketplaceName/:marketplaceName"
                                       component={AddressSelection}/>
                                <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/confirmation/:branchMarketplaceName/:marketplaceName"
                                       component={SummaryAndConfirmation}/>
                                <Route path="/orderCompletion/:branchMarketplaceId/:marketplaceId/confirmation-message/:totalLoyaltyPoints/:branchMarketplaceName/:marketplaceName"
                                       component={OrderCompletionMessagePage}/>
                            </div>
                        </>
                        }
                    </Fragment>
                )}
            </Media>
        </Wrapper>
    );
}

export default (OrderCompletionPage)