import React, { useEffect } from 'react';
import styles from './SearchResultsHeader.module.css';
import InfoBox from "./InfoBox/InfoBox";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Wrapper from "../../UI/Wrapper/Wrapper";
import Background from "../../UI/Background/Background";
import CommercialImagesLink from "../../../config.json";
import {redirectToCommercialLink} from "../../GobalFunctions/GlobalFunctions";

/*Komponenta za smestanje akcija stranice pretrage.*/

const SearchResultsHeader = (props) => {

    let backgroundHeaderColor = props.commercial !== null ? (props.advCategoryImage ? props.advCategoryImage.backgroundColor : props.commercial.backgroundColor) : "#F6F6F6";

    useEffect(() => {

        //uzima sa back-a random reklamu
        if(props.filterBreadcrumbs && props.commercial === null)
            props.onFetchRandomAdvertisement();

        return () => {
            props.onResetAppPageStoreValues();
            if(props.filterBreadcrumbs && props.advCategoryImage === null)
            props.onResetAdvCategoryImageFetched(null);
        }

    },[props.filterBreadcrumbs, props.commercial]);

    return (
        <Wrapper>
            <Background style={{height: "450px", background:backgroundHeaderColor}}/>
            <div className={styles.SearchResultHeader}
                 id="header">
                <div className={styles.SearchResultHeaderHolder}>
                    {/*{!window.location.pathname.includes('searched-articles') &&*/}
                    <InfoBox/>
                    <div className={styles.CommercialImage}
                         onClick={()=> {props.commercial !== null && props.commercial.actionLink !== null && redirectToCommercialLink(props.commercial.actionLink)}}>
                        <img
                            src={props.commercial !== null && (props.advCategoryImage ? CommercialImagesLink.CommercialImagesLink + props.advCategoryImage.imageLink : CommercialImagesLink.CommercialImagesLink + props.commercial.imageLink)}
                            alt="commercial image" style={{width: "620px"}}/>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

const mapStateToProps = (state) => {
    return {
        // showGoogleMap: state.appPage.showGoogleMap,
        commercial: state.mainPage.advertisement,
        shopFilter: state.filter.shopFilter,
        advCategoryImage: state.filter.advCategoryImage,
        filterBreadcrumbs: state.filter.filterBreadcrumbs,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onSelectedStoreChanged: (selectedStore) => dispatch(actions.selectedStoreChanged(selectedStore)),
        // onShowGoogleMap: (showGoogleMap) => dispatch(actions.showGoogleMapChanged(showGoogleMap)),
        onResetAppPageStoreValues: () => dispatch(actions.resetAppPageStoreValues()),
        onFetchRandomAdvertisement: () => dispatch(actions.fetchRandomAdvertisement()),
        onResetAdvCategoryImageFetched: ()=> dispatch(actions.advCategoryImageFetched())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResultsHeader));
