import axios from 'axios';
import {userInfo} from "../store/actions";
import {checkLoginTimeout, loginSuccess} from "../store/actions/authPageActions";

//Disable for deploy
// export const baseUrl = 'http://localhost:8080';

//Enable for dev
export const baseUrl = 'https://apoteka.rs:8443/apoteka_rs_server';

//Enable for deploy
// export const baseUrl = 'https://apoteka.rs:8443/apoteka_rs_server';


const instance = axios.create({
    baseURL: baseUrl
});

const onResponse = (response) => {
    return response;
};

const onResponseError = (error) => {
    console.log(error.response);

    if (error.response) {
        // Access Token was expired
        if (
            error.response.status === 401
            // error.response.data.error === "invalid_token" &&
            // error.response.data.error_description.startsWith("Access token expired")
        ) {

                const params = new URLSearchParams();
                params.append('grant_type', 'refresh_token');
                params.append('refresh_token', localStorage.getItem('refreshToken'));
                axios.post(baseUrl + "/auth/oauth/token", params,{
                    auth: {
                        username: 'apoteka_rs',
                        password: 'F7Q8T97@a\'QveBX?'
                    },
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then((response) => {
                    const expirationDate = new Date (new Date().getTime() + response.data.expires_in * 1000);

                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('refreshToken', response.data.refresh_token);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('id', response.data.userDetails.id);
                    localStorage.setItem('email', response.data.userDetails.username);
                    localStorage.setItem('firstName', response.data.userDetails.firstName);
                    localStorage.setItem('secondName', response.data.userDetails.secondName);
                    // localStorage.setItem('userPhoto', response.data.userDetails.userPhoto);
                    localStorage.setItem('LBO', response.data.userDetails.lbo);
                    localStorage.setItem('roles', response.data.userAuthorities);
                    localStorage.setItem('isGoogleLogin', JSON.stringify(false))
                    // localStorage.setItem('color', '#' + colors[Object.keys(colors)[rand]]);
                    // dispatch(userInfo({
                    //     id: data.userDetails.id,
                    //     username: data.userDetails.username,
                    //     password: "",
                    //     firstName: data.userDetails.firstName,
                    //     secondName: data.userDetails.secondName,
                    //     phoneNumber: data.userDetails.phoneNumber,
                    //     LBO: data.userDetails.lbo,
                    //     userPhoto: data.userDetails.userPhoto,
                    //     roles: data.userAuthorities}));
                    // dispatch(loginSuccess(data.access_token));
                    // dispatch(checkLoginTimeout(data.expires_in));
                }).catch((error) => {
                    console.log(error);
                    return error;
                });

                return;
        }
    }
    console.log(error)
    return error;
};
instance.defaults.params = {};
instance.interceptors.request.use(function (config) {
    config.params['access_token'] = localStorage.getItem('token');
    // const token = localStorage.getItem("token");
    // if (token !== null) {
    //     config.headers["Authorization"] = `bearer ${token}`;
    // }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// instance.interceptors.response.use(onResponse, onResponseError);



export default instance;
